<div class="ui grid">
  <div class="row">
    <div class="one wide column"></div>
    <div class="column">
      <h1 class="ui header">Kontakt</h1>
    </div>
    <div class="one wide column"></div>
  </div>
  <div class="row">
    <div class="one wide column"></div>
    <div class="five wide column">
      <div class="item">
        <div class="content">
          <h3 class="header">Technische Probleme und Schwierigkeiten</h3>
          <div class="description">
            <p>
              Bei technischen Problemen beschreiben Sie Ihr Anliegen bitte ausführlich und fügen nach Möglichkeit
              einen Screenshot des Problems und ihren verwendeten Browser an.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="five wide column">
      <div class="item">
        <div class="content">
          <h3 class="header">Fragen und Hinweise</h3>
          <div class="description">
            <p>
              Bei Fragen und für Hinweise können Sie sich gerne direkt an das FIT-Team der Universität Kassel wenden.
              Wir werden versuchen Ihr Anliegen schnellst möglich zu bearbeiten.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="four wide column">
      <div class="item">
        <div class="content">
          <h3 class="header">Kontaktmöglichkeiten</h3>
          <div class="description">
            <p>
              Email: <a href="mailto:fit@uni-kassel.de">fit&#64;uni-kassel.de</a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="one wide column"></div>
  </div>
</div>

